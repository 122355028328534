/* global eisai */

import { onReady } from "../../utils/events/onReady";
import { addEvent, removeEvent } from "../../utils/events/events";

export class ExternalLink {
    /**
     * Internal placeholder for DOM-objects
     *
     * @type {object}
     * @ignore
     */
    dom = {
        container: undefined
    };

    /**
     *
     * @param {Element} domReference - The element to work from
     */

    constructor(domReference) {
        this.dom.container = domReference;
        this.dom.externalLinkSetter = null;
        this.dom.classList = {
            modal: "modal__external-link",
            modalSiteSwitch: "modal__site-switch",
            modalCloseButton: "modal__close-button",
            modalTargetLink: "button--icon-cta",
            modalCancelButton: "button--icon-arrow-back",
            modalBackdrop: "modal-backdrop",
            gatedContent: "gated-content",
            gatedContentButton: "button--secondary",
            overlayTrigger: "overlay-trigger",
            navigationLink: "navigation__list"
        };
        this.whitelistUrls = eisai.whitelistUrls || [];
        this.triggerList = eisai.triggerList || [];
        onReady(() => this.init(domReference));
    }

    currentHost(linkElement) {
        return linkElement.host === window.location.host;
    }

    externalLink(linkElement) {
        if (
            this.whitelistUrls.length &&
            this.whitelistUrls.includes(linkElement)
        ) {
            return true;
        }
    }

    siteSwitchLink(linkElement) {
        if (
            this.triggerList.length &&
            this.triggerList.includes(linkElement.hostname)
        ) {
            return true;
        }
    }

    attachAllLinkProperties() {
        const hrefs = document.querySelectorAll(
            `a:not(.${this.dom.classList.modal} .${this.dom.classList.modalTargetLink}):not(.${this.dom.classList.gatedContent} .${this.dom.classList.gatedContentButton}):not(.${this.dom.classList.overlayTrigger})`
        );

        hrefs.forEach(item => {
            const href = item.getAttribute("href");
            let url = href;
            if (url && (url.startsWith("mailto:") || url.startsWith("tel:"))) {
                return;
            }
            if (url) {
                url = url.charAt(0);
                if (this.currentHost(item)) {
                    return;
                }

                if (!this.externalLink(href) && url !== "/" && url !== "#") {
                    item.setAttribute("data-modal-trigger", "external-link");
                }

                if (this.siteSwitchLink(item)) {
                    item.setAttribute("data-modal-trigger", "site-switch");
                    if (
                        document.querySelector(
                            `.${this.dom.classList.modalSiteSwitch}`
                        ) === null
                    ) {
                        item.setAttribute("target", "_blank");
                        item.removeAttribute(
                            "data-modal-trigger",
                            "external-link"
                        );
                    }
                }
            }
        });
    }

    onAttachExternalLinkClickEvent(event) {
        event.preventDefault();
        const href =
            event.currentTarget.getAttribute("href") ||
            event.currentTarget.parentNode.getAttribute("href");
        const targetLink = document.querySelector(
            `.${this.dom.classList.modal} .${this.dom.classList.modalTargetLink}`
        );
        targetLink.setAttribute("href", href);

        removeEvent(window, "keydown");
        window.addEventListener("keydown", event => {
            if (event.key === "Escape") {
                this.resetLinkProperties();
            }
        });
    }

    onAttachSiteSwitchLinkClickEvent(event) {
        event.preventDefault();
        const href =
            event.currentTarget.getAttribute("href") ||
            event.currentTarget.parentNode.getAttribute("href");
        const targetLink = document.querySelector(
            `.${this.dom.classList.modalSiteSwitch} .${this.dom.classList.modalTargetLink}`
        );
        targetLink.setAttribute("data-href", href);

        removeEvent(window, "keydown");
        window.addEventListener("keydown", event => {
            if (event.key === "Escape") {
                this.resetLinkProperties();
            }
        });
    }

    attachExternalSiteSwitchEvent() {
        const targetLink = document.querySelector(
            `.${this.dom.classList.modalSiteSwitch} .${this.dom.classList.modalTargetLink}`
        );
        const link = targetLink.getAttribute("data-href");
        window.open(link, "_blank");
        window.location.reload();
    }

    resetLinkProperties() {
        const targetLinkExternal = document.querySelector(
            `.${this.dom.classList.modal} .${this.dom.classList.modalTargetLink}`
        );
        const targetLinkSiteSwitch = document.querySelector(
            `.${this.dom.classList.modalSiteSwitch} .${this.dom.classList.modalTargetLink}`
        );
        if (targetLinkExternal !== null) {
            targetLinkExternal.setAttribute("href", "");
        }
        if (targetLinkSiteSwitch !== null) {
            targetLinkSiteSwitch.setAttribute("data-href", "");
        }
    }

    /**
     * Bind events we need to the DOM-elements.
     */
    bindEvents() {
        addEvent(
            this.dom.externalLinkSetter,
            "click",
            this.onAttachExternalLinkClickEvent.bind(this)
        );
        addEvent(
            this.dom.siteSwitchLinkSetter,
            "click",
            this.onAttachSiteSwitchLinkClickEvent.bind(this)
        );
        addEvent(
            this.dom.siteSwitchExternalLink,
            "click",
            this.attachExternalSiteSwitchEvent.bind(this)
        );
        addEvent(
            this.dom.cancelTrigger,
            "click",
            this.resetLinkProperties.bind(this)
        );
    }

    init() {
        this.attachAllLinkProperties();
        this.dom.externalLinkSetter = document.querySelectorAll(
            `[data-modal-trigger='external-link']`
        );
        this.dom.siteSwitchLinkSetter = document.querySelectorAll(
            `[data-modal-trigger="site-switch"]`
        );
        this.dom.siteSwitchExternalLink = document.querySelectorAll(
            `.${this.dom.classList.modalSiteSwitch} .${this.dom.classList.modalTargetLink}`
        );
        this.dom.cancelTrigger = document.querySelectorAll(
            `.${this.dom.classList.modal} .${this.dom.classList.modalCloseButton},
            .${this.dom.classList.modal} .${this.dom.classList.modalCancelButton},
            .${this.dom.classList.modalSiteSwitch} .${this.dom.classList.modalCloseButton},
            .${this.dom.classList.modalSiteSwitch} .${this.dom.classList.modalCancelButton},
            .${this.dom.classList.modalBackdrop}`
        );
        this.bindEvents();
    }
}
