import { onReady } from "../../utils/events/onReady";
import { throttle } from "../../utils/dom/throttle";
import { bindScrollToElement } from "../../utils/events/bindScrollToElement";
import {
    currentBreakpoint,
    breakpointIndex
} from "../../utils/events/onWindowResize";

export class InPageNavigation {
    /**
     * Internal placeholder for DOM-objects
     *
     * @type {object}
     * @ignore
     */
    dom = {
        container: undefined
    };

    /**
     *
     * @param {Element} domReference - The element to work from
     */
    constructor(domReference) {
        this.dom.container = domReference;
        this.dom.hash = window.location.hash;
        this.dom.pathname = window.location.pathname;
        this.dom.interval = 1000;
        this.dom.transition = 600;
        this.dom.navHeight = document.querySelector("header")?.clientHeight;
        this.dom.classList = {
            activeClass: "active",
            hiddenClass: "hidden"
        };
        onReady(() => this.init(domReference));
    }

    onInPageNavigationLoad() {
        const inPageNavigation = this.dom.container.firstElementChild;
        const section = document.querySelectorAll("[data-in-page-navigation]");
        if (section && section.length) {
            section[0].classList.add("component__first-item");
            section.forEach(item => {
                if (item.dataset.inPageNavigation) {
                    const template = `<li><a href="#${item.id}">${item.dataset.inPageNavigation}</a></li>`;
                    inPageNavigation.insertAdjacentHTML("beforeend", template);
                }
            });
        }
    }

    onInPageNavigationScroll() {
        const inPageNavigation = this.dom.container;
        const links = inPageNavigation.querySelectorAll("a");
        const section = document.querySelectorAll("[data-in-page-navigation]");
        if (section && section.length) {
            section.forEach(item => {
                const id = item.getAttribute("id");
                if (item.dataset.inPageNavigation) {
                    const sectionTop = item.offsetTop - this.dom.navHeight;
                    if (window.scrollY >= sectionTop) {
                        links.forEach(item =>
                            item.classList.remove(
                                this.dom.classList.activeClass
                            )
                        );
                        const currentLink = inPageNavigation.querySelector(
                            `a[href="#${id}"]`
                        );
                        currentLink.classList.add(
                            this.dom.classList.activeClass
                        );
                    }
                }
            });
        }
    }

    onInPageNavigationClick() {
        const inPageNavigation = this.dom.container;
        const links = inPageNavigation.querySelectorAll("a");
        links.forEach(item => {
            item.addEventListener("click", event => {
                event.preventDefault();
                const href = item.getAttribute("href");
                const section = document.querySelector(`${href}`);
                section.scrollIntoView({});
                let lastPos = null;
                requestAnimationFrame(checkPos);
                function checkPos() {
                    const newPos = section.getBoundingClientRect().top;
                    if (newPos !== lastPos) {
                        lastPos = newPos;
                        requestAnimationFrame(checkPos);
                        window.history.replaceState(null, null, href);
                    }
                }
            });
        });
    }

    init(domReference) {
        const isMobile = currentBreakpoint < breakpointIndex.lg;
        const inPageNavigation = domReference;
        const inPageNavigationInner = inPageNavigation.querySelector(
            ".in-page-navigation__inner"
        );
        const section = document.querySelectorAll("[data-in-page-navigation]");
        let sections = [...section].filter(
            item => item.getAttribute("data-in-page-navigation") !== ""
        );
        if (sections.length) {
            this.onInPageNavigationLoad();
            this.onInPageNavigationScroll();
            this.onInPageNavigationClick();

            document.addEventListener("scroll", () => {
                throttle(this.onInPageNavigationScroll(), this.dom.interval);
            });
            inPageNavigation.classList.remove(this.dom.classList.hiddenClass);
            if (isMobile) {
                bindScrollToElement(document.body, inPageNavigationInner, 40);
            }
        } else {
            inPageNavigation.classList.add(this.dom.classList.hiddenClass);
        }
    }
}
