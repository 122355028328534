/* Bind vertical scrolling from one element to horiziontak scrolling in another
 * import { bindScrollToElement } from "./utils/events/bindScrollToElement";
 * bindScrollToElement( div1, div2 );
 */

export function bindScrollToElement(elem1, elem2, buffer = 0) {
    elem1.onscroll = function() {
        const elem1Height = elem1.scrollHeight;
        const elem1Offset = window.scrollY / elem1Height;
        const elem2Offset = elem2.scrollWidth - elem2.offsetWidth + buffer;
        elem2.scrollLeft = elem2Offset * elem1Offset;
    };
}
