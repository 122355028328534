import { onReady } from "../../utils/events/onReady";
import { addEvent } from "../../utils/events/events";

export class Accordion {
    /**
     * Internal placeholder for DOM-objects
     *
     * @type {object}
     * @ignore
     */
    dom = {
        container: undefined
    };

    /**
     *
     * @param {Element} domReference - The element to work from
     */
    constructor(domReference) {
        this.dom.container = domReference;
        this.dom.classList = {
            openClass: "accordion__list--open"
        };

        onReady(() => this.init(domReference));
    }

    onAccordionClickEvent(event) {
        event.preventDefault();
        const elem = event.currentTarget;
        if (elem.classList.contains(this.dom.classList.openClass)) {
            elem.classList.remove(this.dom.classList.openClass);
            elem.setAttribute("aria-expanded", false);
        } else {
            elem.classList.add(this.dom.classList.openClass);
            elem.setAttribute("aria-expanded", true);
        }
    }

    /**
     * Bind events we need to the DOM-elements.
     */
    bindEvents() {
        addEvent(
            this.dom.accordionTrigger,
            "click",
            this.onAccordionClickEvent.bind(this)
        );
    }

    init(domReference) {
        this.dom.accordionTrigger = this.dom.container.querySelectorAll(
            ".accordion__list > li > a"
        );
        this.bindEvents();
    }
}
