const eisai = {
    threeQVideoServiceId: "qxiCD5aN_",
    threeQBaseURL: "https://sdn.3qsdn.com",
    threeQPath: "/api/v2",
    threeQAPIKey: "XXXXXX",
    triggerList: window.triggerList || [],
    whitelistUrls: window.whitelistUrls || []
};

window.eisai = eisai;
