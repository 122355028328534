/* global eisai */
/* global js3q */
/* global UC_UI */
/* global dataLayer */
/* global AdobeDC */

import { addEvent, addEventOnce, removeEvent } from "../../utils/events/events";
import { onReady } from "../../utils/events/onReady";
import {
    disableScrollLock,
    enableScrollLock
} from "../../utils/dom/scrollLock";
import { hasClass } from "../../utils/dom/classList";

export class Overlay {
    /**
     * Internal placeholder for DOM-objects
     *
     * @type {object}
     * @ignore
     */
    dom = {
        container: undefined
    };

    /**
     *
     * @param {Element} domReference - The element to work from
     */
    constructor(domReference, overlayId) {
        this.dom.container = domReference;
        this.dom.objJs3qVideoPlayer = "js3qVideoPlayer";
        this.dom.classList = {
            hiddenClass: "hidden",
            overlay: "overlay",
            overlayActive: "overlay--active",
            overlayClose: "overlay__close",
            audioTrigger: "button--icon-audio",
            overlayVideo: "overlay__video",
            overlayAudio: "overlay__audio",
            overlayPdf: "overlay__pdf",
            overlayImage: "overlay__image"
        };
        onReady(() => this.init(domReference, overlayId));
    }

    openOverlay(event, overlayId) {
        event.preventDefault();
        this.closeOverlay(event);
        let overlay = this.dom.container.querySelector(
            `.overlay[data-overlay-id='${overlayId}']`
        );
        if (overlayId === "overlay-pdf" || overlayId === "overlay-image") {
            overlay = document.querySelector(
                `.overlay[data-overlay-id='${overlayId}']`
            );
        }
        if (overlay) {
            overlay.classList.add(this.dom.classList.overlayActive);
        }
        if (
            !event.currentTarget.classList.contains(
                `${this.dom.classList.audioTrigger}`
            )
        ) {
            enableScrollLock();
        }
    }

    onPdfLoadEvent(pdfId) {
        if (AdobeDC) {
            var dataId = document
                .querySelector(".overlay__pdf .content-wrapper")
                .getAttribute("data-viewer-id");
            var adobeDCView = new AdobeDC.View({
                clientId: dataId,
                divId: "adobe-dc-view"
            });
            adobeDCView.previewFile({
                content: { location: { url: pdfId } },
                metaData: { fileName: "pdf" }
            });
        }
    }

    onImageLoadEvent(imgSrc) {
        const overlayImage = document.querySelector(
            `.${this.dom.classList.overlayImage} img`
        );
        overlayImage.src = imgSrc;
    }

    resetPdfOverlay() {
        const pdfIframe = document.querySelector(
            `.${this.dom.classList.overlayPdf} iframe`
        );
        pdfIframe.src = "";
    }

    resetImageOverlay() {
        const imgSrc = document.querySelector(
            `.${this.dom.classList.overlayImage} img`
        );
        imgSrc.src = "";
    }

    cookieConsent() {
        let consent = false;
        if (!window.UC_UI || !UC_UI.isInitialized()) {
            window.addEventListener("UC_UI_INITIALIZED", () => {
                if (
                    UC_UI.getServicesBaseInfo().find(
                        data => data.id === eisai.threeQVideoServiceId
                    ).consent.status
                ) {
                    consent = true;
                }
            });
        } else if (
            UC_UI.getServicesBaseInfo().find(
                data => data.id === eisai.threeQVideoServiceId
            ).consent.status
        ) {
            consent = true;
        }
        return consent;
    }
    mediaTracking(media, id, name) {
        // event tracking
        media.on("media.autoplay", function(data) {
            dataLayer.push({
                event: "gtm_event",
                category: name,
                id: this.configLoader.MainStage.config.metadata.Title || id,
                type: "media.autoplay"
            });
        });
        media.on("player.playRequest", function(data) {
            dataLayer.push({
                event: "gtm_event",
                category: name,
                id: this.configLoader.MainStage.config.metadata.Title || id,
                type: "player.playRequest"
            });
        });

        media.on("player.pauseRequest", function(data) {
            dataLayer.push({
                event: "gtm_event",
                category: name,
                id: this.configLoader.MainStage.config.metadata.Title || id,
                type: "player.pauseRequest"
            });
        });
        // media.timeChange event fires every 250ms
        let timeValue = 0;
        media.on("media.timeChange", function(data) {
            timeValue = data;
        });
        //check every 5 seconds if media.timeChange event is fired then push timeValue to dataLayer
        setInterval(() => {
            if (timeValue) {
                dataLayer.push({
                    event: "gtm_event",
                    category: name,
                    id:
                        media.configLoader.MainStage.config.metadata.Title ||
                        id,
                    type: "media.timeChange",
                    value: timeValue,
                    length: media.configLoader.originalProcessedConfig.vlength
                });
                timeValue = 0;
            }
        }, 5000);

        media.on("media.complete", function(data) {
            dataLayer.push({
                event: "gtm_event",
                category: name,
                id: this.configLoader.MainStage.config.metadata.Title || id,
                type: "media.complete"
            });
        });
    }
    onVideoLoadEvent(videoId, destroyVideo) {
        const videoElem = this.dom.container.querySelector(
            `.${this.dom.classList.overlayActive} [data-video-id]`
        );
        let id;
        if (typeof js3q !== "undefined") {
            if (
                destroyVideo &&
                typeof this.dom.objJs3qVideoPlayer !== "string"
            ) {
                id = "";
                this.dom.objJs3qVideoPlayer.pause();
                this.dom.objJs3qVideoPlayer.destroy();
            } else if (videoElem) {
                id = videoElem.getAttribute("id");
                this.dom.objJs3qVideoPlayer = new js3q({
                    dataid: videoId,
                    container: id,
                    layout: "default",
                    width: "100%",
                    height: "100%",
                    autoplay: true
                });
                this.dom.objJs3qVideoPlayer.once("media.ready", () => {
                    videoElem.classList.remove(this.dom.classList.hiddenClass);
                });
                this.mediaTracking(
                    this.dom.objJs3qVideoPlayer,
                    videoId,
                    "3q_video"
                );
            } else {
                return;
            }
        }
    }

    onAudioLoadEvent(audioId, destroyAudio) {
        const audioElem = this.dom.container.querySelector(
            `.${this.dom.classList.overlayActive} [data-audio-id]`
        );
        let id;
        if (typeof js3q !== "undefined") {
            if (
                destroyAudio &&
                typeof this.dom.objJs3qVideoPlayer !== "string"
            ) {
                id = "";
                this.dom.objJs3qVideoPlayer.pause();
                this.dom.objJs3qVideoPlayer.destroy();
            } else if (audioElem) {
                id = audioElem.getAttribute("id");
                this.dom.objJs3qVideoPlayer = new js3q({
                    dataid: audioId,
                    container: id,
                    layout: "fixed",
                    width: "100%",
                    height: "100%",
                    autoplay: true
                });
                this.mediaTracking(
                    this.dom.objJs3qVideoPlayer,
                    audioId,
                    "3q_audio"
                );
            } else {
                return;
            }
        }
    }

    handleOverlayReset() {
        const overlayActive = document.querySelector(
            `.${this.dom.classList.overlayActive}`
        );
        if (overlayActive) {
            const overlayClose = overlayActive.querySelector(
                `.${this.dom.classList.overlayClose}`
            );
            overlayClose.click();
        }
    }

    closeOverlay(event) {
        event.preventDefault();
        const overlays = document.querySelectorAll(".overlay");
        overlays.forEach(item => {
            item.classList.remove(this.dom.classList.overlayActive);
        });
        disableScrollLock();
    }

    handleKeyEvent() {
        const videoWrapper = document.querySelectorAll(
            `.${this.dom.classList.overlayVideo}`
        );
        const audioWrapper = document.querySelectorAll(
            `.${this.dom.classList.overlayAudio}`
        );
        let isVideo;
        let isAudio;

        removeEvent(window, "keydown");
        window.addEventListener("keydown", event => {
            videoWrapper.forEach(item => {
                hasClass(item, this.dom.classList.overlayActive);
                isVideo = true;
            });
            audioWrapper.forEach(item => {
                hasClass(item, this.dom.classList.overlayActive);
                isAudio = true;
            });

            if (event.key === "Escape") {
                if (isVideo) {
                    this.onVideoLoadEvent(event, true);
                } else if (isAudio) {
                    this.onAudioLoadEvent(event, true);
                }
                this.closeOverlay(event);
            }
        });
    }

    init(domReference) {
        const self = this;
        const hrefs = domReference.querySelectorAll("a, button");
        const close = document.querySelectorAll(
            `.${self.dom.classList.overlayClose}`
        );
        const pdfClose = document.querySelector(
            `.overlay__pdf .${self.dom.classList.overlayClose}`
        );
        const imgClose = document.querySelector(
            `.overlay__image .${self.dom.classList.overlayClose}`
        );

        addEventOnce(hrefs, "click", event => {
            this.handleOverlayReset();
            const isImage =
                event.currentTarget.dataset.imageTriggerId ||
                event.currentTarget.parentNode.dataset.imageTriggerId;
            const isPDF =
                event.currentTarget.dataset.pdfTriggerId ||
                event.currentTarget.parentNode.dataset.pdfTriggerId;
            const isVideo =
                event.currentTarget.dataset.videoTriggerId ||
                event.currentTarget.parentNode.dataset.videoTriggerId;
            const isAudio =
                event.currentTarget.dataset.audioTriggerId ||
                event.currentTarget.parentNode.dataset.audioTriggerId;
            let target;
            if ((isVideo || isAudio) && self.cookieConsent() === false) {
                target = "overlay-consent";
            } else {
                target =
                    event.currentTarget.dataset.overlayTrigger ||
                    event.currentTarget.parentNode.dataset.overlayTrigger;
            }
            if (target && isImage) {
                this.openOverlay(event, target);
                this.onImageLoadEvent(
                    event.currentTarget.dataset.imageTriggerId ||
                        event.currentTarget.parentNode.dataset.imageTriggerId
                );
            } else if (target && isPDF) {
                this.openOverlay(event, target);
                this.onPdfLoadEvent(
                    event.currentTarget.dataset.pdfTriggerId ||
                        event.currentTarget.parentNode.dataset.pdfTriggerId
                );
            } else if (target && isVideo) {
                this.openOverlay(event, target);
                if (self.cookieConsent() !== false) {
                    this.onVideoLoadEvent(
                        event.currentTarget.dataset.videoTriggerId ||
                            event.currentTarget.parentNode.dataset
                                .videoTriggerId
                    );
                }
            } else if (target && isAudio) {
                this.openOverlay(event, target);
                if (self.cookieConsent() !== false) {
                    this.onAudioLoadEvent(
                        event.currentTarget.dataset.audioTriggerId ||
                            event.currentTarget.parentNode.dataset
                                .audioTriggerId
                    );
                }
            } else {
                return;
            }
        });

        addEventOnce(close, "click", event => {
            const videoWrapper = document.querySelectorAll(".overlay__video");
            const audioWrapper = document.querySelectorAll(".overlay__audio");
            let isVideo;
            let isAudio;
            videoWrapper.forEach(item => {
                hasClass(item, this.dom.classList.overlayActive);
                isVideo = true;
            });
            audioWrapper.forEach(item => {
                hasClass(item, this.dom.classList.overlayActive);
                isAudio = true;
            });
            if (isVideo) {
                this.onVideoLoadEvent(event, true);
            } else if (isAudio) {
                this.onAudioLoadEvent(event, true);
            }
            this.closeOverlay(event);
        });

        addEvent(pdfClose, "click", event => {
            this.closeOverlay(event);
            this.resetPdfOverlay();
        });

        addEvent(imgClose, "click", event => {
            this.closeOverlay(event);
            this.resetImageOverlay();
        });

        this.handleKeyEvent();
    }
}
