/* global eisai */
/* global js3q */
/* global UC_UI */

import { onReady } from "../../utils/events/onReady";

export class Hero {
    /**
     * Internal placeholder for DOM-objects
     *
     * @type {object}
     * @ignore
     */
    dom = {
        container: undefined
    };

    /**
     *
     * @param {Element} domReference - The element to work from
     */
    constructor(domReference) {
        this.dom.container = domReference;
        this.dom.classList = {
            hiddenClass: "hidden"
        };

        onReady(() => this.init(domReference));
    }

    cookieConsent() {
        let consent = false;
        if (!window.UC_UI || !UC_UI.isInitialized()) {
            window.addEventListener("UC_UI_INITIALIZED", () => {
                if (
                    UC_UI.getServicesBaseInfo().find(
                        data => data.id === eisai.threeQVideoServiceId
                    ).consent.status
                ) {
                    consent = true;
                }
            });
        } else if (
            UC_UI.getServicesBaseInfo().find(
                data => data.id === eisai.threeQVideoServiceId
            ).consent.status
        ) {
            consent = true;
        }
        return consent;
    }

    onHeroVideoLoadEvent() {
        const videoElem = this.dom.container.querySelector(
            "[data-video-id-hero]"
        );
        if (videoElem && typeof js3q !== "undefined") {
            const videoId = videoElem.getAttribute("data-video-id-hero");
            const id = videoElem.getAttribute("id");
            const js3qVideoPlayer = new js3q({
                dataid: videoId,
                container: id,
                autoplay: true,
                allowmutedautoplay: true,
                loop: true,
                muted: true,
                controls: false,
                width: "100%",
                height: "100%"
            });
            js3qVideoPlayer.once("media.ready", () => {
                videoElem.classList.remove(this.dom.classList.hiddenClass);
            });
            return js3qVideoPlayer;
        }
    }

    init() {
        setTimeout(() => {
            if (this.cookieConsent() !== false) {
                this.onHeroVideoLoadEvent();
            }
        }, 500);
    }
}
