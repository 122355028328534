import { addEvent } from "../../utils/events/events";
import { onReady } from "../../utils/events/onReady";

export class RibbonImage {
    /**
     * Internal placeholder for DOM-objects
     *
     * @type {object}
     * @ignore
     */
    dom = {
        container: undefined
    };

    /**
     *
     * @param {Element} domReference - The element to work from
     */
    constructor(domReference) {
        this.dom.container = domReference;
        this.dom.classList = {
            container: "ribbon-image"
        };

        onReady(() => this.init(domReference));
    }

    init(domReference) {
        const button = domReference.querySelector(
            ".ribbon-image__expand .button"
        );
        addEvent(button, "click", () =>
            domReference.classList.add(
                `${this.dom.classList.container}__expanded`
            )
        );
    }
}
