/* global UC_UI */

import { onReady } from "../../utils/events/onReady";

export class StaticIframe {
    /**
     * Internal placeholder for DOM-objects
     *
     * @type {object}
     * @ignore
     */
    dom = {
        container: undefined
    };

    /**
     *
     * @param {Element} domReference - The element to work from
     */
    constructor(domReference) {
        this.dom.container = domReference;
        this.dom.classList = {
            hiddenClass: "hidden"
        };
        onReady(() => this.init(domReference));
    }
    cookieConsent(id) {
        let consent = false;
        if (!window.UC_UI || !UC_UI.isInitialized()) {
            window.addEventListener("UC_UI_INITIALIZED", () => {
                if (
                    UC_UI.getServicesBaseInfo()?.find(data => data.id === id)
                        ?.consent.status
                ) {
                    consent = true;
                }
            });
        } else if (
            UC_UI.getServicesBaseInfo()?.find(data => data.id === id)?.consent
                .status
        ) {
            consent = true;
        }
        return consent;
    }

    onCookieConsentEvent() {
        const iframe = this.dom.container.querySelector("iframe");
        const consentWrapper = this.dom.container.querySelector(
            ".static-iframe__consent"
        );
        if (iframe.dataset.usercentricsId) {
            if (this.cookieConsent(iframe.dataset.usercentricsId) !== false) {
                iframe.src = iframe.dataset.src;
                consentWrapper.classList.add(this.dom.classList.hiddenClass);
                iframe.classList.remove(this.dom.classList.hiddenClass);
            } else {
                consentWrapper.classList.remove(this.dom.classList.hiddenClass);
                iframe.classList.add(this.dom.classList.hiddenClass);
                iframe.src = "";
            }
        } else {
            iframe.src = iframe.dataset.src;
        }
    }

    init() {
        setTimeout(() => {
            this.onCookieConsentEvent();
        }, 500);
        if (!window.UC_UI || !UC_UI.isInitialized()) {
            window.addEventListener("UC_UI_INITIALIZED", () => {
                window.addEventListener("ucEvent", () => {
                    this.onCookieConsentEvent();
                });
            });
        } else {
            window.addEventListener("ucEvent", () => {
                this.onCookieConsentEvent();
            });
        }
    }
}
