import { onReady } from "../../utils/events/onReady";

export class GatedContent {
    /**
     * Internal placeholder for DOM-objects
     *
     * @type {object}
     * @ignore
     */
    dom = {
        container: undefined
    };

    /**
     *
     * @param {Element} domReference - The element to work from
     */

    constructor(domReference) {
        this.dom.container = domReference;
        this.dom.hash = window.location.hash;

        onReady(() => this.init(domReference));
    }

    attachGatedContentProperties(target) {
        const link = this.dom.hash.split("#")[1];
        if (link) {
            const href = target.getAttribute("href");
            target.setAttribute("href", href + "?hash=" + link);
        }
    }

    init(domReference) {
        const target = domReference.querySelector(".button--icon-cta");
        this.attachGatedContentProperties(target);
    }
}
