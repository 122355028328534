import { addEvent, addEventOnce, removeEvent } from "../../utils/events/events";
import { onReady } from "../../utils/events/onReady";
import {
    disableScrollLock,
    enableScrollLock
} from "../../utils/dom/scrollLock";
import {
    currentBreakpoint,
    breakpointIndex
} from "../../utils/events/onWindowResize";
import { hasClass } from "../../utils/dom/classList";

export class Modal {
    /**
     * Internal placeholder for DOM-objects
     *
     * @type {object}
     * @ignore
     */
    dom = {
        container: undefined
    };

    /**
     *
     * @param {Element} domReference - The element to work from
     */
    constructor(domReference, modalId) {
        this.dom.container = domReference;
        this.dom.classList = {
            modal: "modal",
            modalActive: "modal--active",
            backdrop: "modal-backdrop",
            newsletterSignup: "newsletter-signup",
            newsletterSignupInput: "pre-email",
            preventKeyEventsClass: "modal__prevent-key-events",
            hiddenClass: "hidden",
            navigationWrapper: "navigation__list-wrapper",
            navigationWrapperOpen: "navigation__list-wrapper--open"
        };

        onReady(() => this.init(domReference, modalId));
    }

    backdrop = document.querySelector(`.modal-backdrop`);

    openModal(event, modalId) {
        event.preventDefault();
        const modal = this.dom.container.querySelector(
            `.modal[data-modal-id='${modalId}']`
        );
        const newsletterSignupInput = this.dom.container.querySelector(
            `#${this.dom.classList.newsletterSignupInput}`
        );
        const emailInput = this.dom.container.querySelector(
            `.${this.dom.classList.newsletterSignup} #email`
        );

        if (modalId === this.dom.classList.newsletterSignup) {
            emailInput.value = newsletterSignupInput.value;
        }

        if (modal) {
            modal.classList.add(`${this.dom.classList.modalActive}`);
            this.backdrop.classList.add(
                `${this.dom.classList.backdrop}--active`
            );
        }

        if (modalId === "search") {
            modal.querySelector(".search__input").focus();
        }

        enableScrollLock();
    }

    closeModal() {
        const modals = document.querySelectorAll(
            `.modal:not(.${this.dom.classList.preventKeyEventsClass})`
        );
        modals.forEach(item => {
            item.classList.remove(`${this.dom.classList.modalActive}`);
            if (hasClass(item, "modal__newsletter-signup")) {
                const preEmail = document.querySelector("#pre-email");
                preEmail.value = "";
            }
        });

        if (this.backdrop) {
            this.backdrop.classList.remove(
                `${this.dom.classList.backdrop}--active`
            );
        }

        const isMobile = currentBreakpoint < breakpointIndex.lg;
        const navigation = document.querySelector(
            `.${this.dom.classList.navigationWrapper}`
        );
        const isNavOpen = hasClass(
            navigation,
            this.dom.classList.navigationWrapperOpen
        );

        if (isMobile && isNavOpen) {
            return;
        } else {
            disableScrollLock();
        }
    }

    handleKeyEvent() {
        removeEvent(window, "keydown");
        window.addEventListener("keydown", event => {
            if (event.key === "Escape") {
                this.closeModal();
            }
        });
    }

    handleClickOpenEvent() {
        const newsletterSignupInput = this.dom.container.querySelector(
            `#${this.dom.classList.newsletterSignupInput}`
        );
        const trigger = document.querySelector(
            `[data-modal-trigger="${this.dom.classList.newsletterSignup}"]`
        );
        removeEvent(window, "keydown");
        window.addEventListener("keydown", event => {
            if (
                newsletterSignupInput &&
                newsletterSignupInput.value.length &&
                newsletterSignupInput === document.activeElement &&
                event.key === "Enter"
            ) {
                trigger.click();
            }
        });
    }

    handleClickOutsideEvent() {
        if (this.backdrop !== null) {
            removeEvent(this.backdrop, "click");
            this.backdrop.addEventListener("click", () => {
                this.closeModal();
            });
        }
    }

    init() {
        const hrefs = document.querySelectorAll("a, button");
        const close = document.querySelectorAll(".modal__close");

        addEventOnce(hrefs, "click", event => {
            const target =
                event.currentTarget.dataset.modalTrigger ||
                event.currentTarget.parentNode.dataset.modalTrigger;
            if (target) {
                this.openModal(event, target);
            }
        });

        addEvent(close, "click", () => this.closeModal());

        this.handleKeyEvent();
        this.handleClickOpenEvent();
        this.handleClickOutsideEvent();
    }
}
