/* global eisai */
/* global js3q */
/* global UC_UI */
/*global dataLayer */

import { onReady } from "../../utils/events/onReady";
import { onWindowResize } from "../../utils/events/onWindowResize";

export class CarouselVideo {
    /**
     * Internal placeholder for DOM-objects
     *
     * @type {object}
     * @ignore
     */
    dom = {
        container: undefined
    };

    /**
     *
     * @param {Element} domReference - The element to work from
     */
    constructor(domReference) {
        this.dom.container = domReference;
        this.dom.classList = {
            hiddenClass: "hidden"
        };
        onReady(() => this.init(domReference));
    }
    cookieConsent() {
        let consent = false;
        if (!window.UC_UI || !UC_UI.isInitialized()) {
            window.addEventListener("UC_UI_INITIALIZED", () => {
                if (
                    UC_UI.getServicesBaseInfo().find(
                        data => data.id === eisai.threeQVideoServiceId
                    ).consent.status
                ) {
                    consent = true;
                }
            });
        } else if (
            UC_UI.getServicesBaseInfo().find(
                data => data.id === eisai.threeQVideoServiceId
            ).consent.status
        ) {
            consent = true;
        }
        return consent;
    }
    onCookieConsentEvent() {
        const consentWrappers = this.dom.container.querySelectorAll(
            ".carousel__item-video-consent"
        );
        consentWrappers.forEach(consentWrapper =>
            consentWrapper.classList.remove(this.dom.classList.hiddenClass)
        );
    }
    onCarouselVideoLoadEvent() {
        const videos = this.dom.container.querySelectorAll(
            ".carousel__item-video-wrapper"
        );

        videos.forEach(videoElem => {
            if (videoElem && typeof js3q !== "undefined") {
                const videoId = videoElem.getAttribute("data-video-id");
                const id = videoElem.getAttribute("id");
                const js3qVideoPlayer = new js3q({
                    dataid: videoId,
                    container: id,
                    width: "100%",
                    height: "100%"
                });
                js3qVideoPlayer.once("media.ready", () => {
                    videoElem.classList.remove(this.dom.classList.hiddenClass);
                });
                //event tracking
                js3qVideoPlayer.on("media.autoplay", function(data) {
                    dataLayer.push({
                        event: "gtm_event",
                        category: "3q_video",
                        id:
                            this.configLoader.MainStage.config.metadata.Title ||
                            videoId,
                        type: "media.autoplay"
                    });
                });
                js3qVideoPlayer.on("player.playRequest", function(data) {
                    dataLayer.push({
                        event: "gtm_event",
                        category: "3q_video",
                        id:
                            this.configLoader.MainStage.config.metadata.Title ||
                            videoId,
                        type: "player.playRequest"
                    });
                });
                js3qVideoPlayer.on("player.pauseRequest", function(data) {
                    dataLayer.push({
                        event: "gtm_event",
                        category: "3q_video",
                        id:
                            this.configLoader.MainStage.config.metadata.Title ||
                            videoId,
                        type: "player.pauseRequest"
                    });
                });

                // media.timeChange event fires every 250ms
                let timeValue = 0;
                js3qVideoPlayer.on("media.timeChange", function(data) {
                    timeValue = data;
                });
                //check every 5 seconds if media.timeChange event is fired then push timeValue to dataLayer
                setInterval(() => {
                    if (timeValue) {
                        dataLayer.push({
                            event: "gtm_event",
                            category: "3q_video",
                            id:
                                js3qVideoPlayer.configLoader.MainStage.config
                                    .metadata.Title || videoId,
                            type: "media.timeChange",
                            value: timeValue,
                            length:
                                js3qVideoPlayer.configLoader
                                    .originalProcessedConfig.vlength
                        });
                        timeValue = 0;
                    }
                }, 5000);

                js3qVideoPlayer.on("media.complete", function(data) {
                    dataLayer.push({
                        event: "gtm_event",
                        category: "3q_video",
                        id:
                            this.configLoader.MainStage.config.metadata.Title ||
                            videoId,
                        type: "media.complete"
                    });
                });
                (() => {
                    const findImagePoster = setInterval(() => {
                        const elem = this.dom.container.querySelector("video");
                        const param = elem?.getAttribute("poster");
                        if (elem && param?.includes("?")) {
                            const newSrc = param.split("?")[0];
                            elem.setAttribute("poster", newSrc);
                            clearInterval(findImagePoster);
                        }
                    }, 1000);
                })();
                return js3qVideoPlayer;
            }
        });
    }

    init(domReference) {
        function cookieConsentHeight() {
            const consentWrappers = domReference.querySelectorAll(
                ".carousel__item-video-consent"
            );
            const img = domReference.querySelector("img");
            consentWrappers.forEach(consentWrapper => {
                consentWrapper.style.height = img?.clientHeight + "px";
            });
        }
        setTimeout(() => {
            if (this.cookieConsent() !== false) {
                this.onCarouselVideoLoadEvent();
            } else {
                this.onCookieConsentEvent();
                cookieConsentHeight();
            }
        }, 500);
        onWindowResize(cookieConsentHeight);
    }
}
