/* global eisai */

import { addEvent, addEventOnce, removeEvent } from "../../utils/events/events";
import {
    onClickOutside,
    removeOnClickOutside
} from "../../utils/events/onClickOutside";
import {
    disableScrollLock,
    enableScrollLock
} from "../../utils/dom/scrollLock";
import {
    currentBreakpoint,
    breakpointIndex,
    onWindowResize
} from "../../utils/events/onWindowResize";
import { scrollTo } from "../../utils/dom/scrollTo";
import { getSiblings } from "../../utils/dom/siblings";
import { onReady } from "../../utils/events/onReady";
import { hasClass } from "../../utils/dom/classList";

export class Navigation {
    /**
     * Internal placeholder for DOM-objects
     *
     * @type {object}
     * @ignore
     */
    dom = {
        container: undefined
    };

    /**
     *
     * @param {Element} domReference - The element to work from
     */
    constructor(domReference) {
        this.dom.container = domReference;
        this.dom.hash = window.location.hash;
        this.dom.pathname = window.location.pathname;
        this.dom.navHeight = document.querySelector("header")?.clientHeight;
        this.dom.classList = {
            navigationWrapper: "navigation__wrapper",
            navigationWrapperOpen: "navigation__wrapper-open",
            navigationListWrapper: "navigation__list-wrapper",
            navigationListWrapperOpen: "navigation__list-wrapper--open",
            navigationList: "navigation__list",
            navigationBackTop: "navigation__back",
            navigationListOpen: "navigation__list--open",
            navigationButton: "navigation__list-button",
            navigationMenu: "navigation__list__menu",
            navigationClose: "navigation__list__close",
            navigationBack: "navigation__list__back",
            navigationPanel: "navigation__list-panel",
            navigationPanelOpen: "navigation__list-panel--open",
            subNavigationList: "navigation__subnavigation-list",
            subNavigationWrapper: "navigation__subnavigation-wrapper",
            subNavigationTrigger: "navigation__subnavigation-trigger",
            subNavigationBack: "navigation__subnavigation-back",
            subNavigationOpen: "navigation__subnavigation-list--open",
            subNavigationActive: "navigation__subnavigation-list--active",
            mobileNavigationTrigger: "navigation__trigger",
            samePageNavigationLink: "navigation__same-page-navigation"
        };
        this.triggerList = eisai.triggerList || [];
        onReady(() => this.init(domReference));
    }

    siteSwitchLink(linkElement) {
        if (
            this.triggerList.length &&
            this.triggerList.includes(linkElement.hostname)
        ) {
            return true;
        }
    }

    // mobile and desktop
    togglePanel({ target }) {
        this.resetSubNavigation();

        const navigationWrapper = document.querySelector(
            `.${this.dom.classList.navigationWrapper}`
        );
        const expanded = target.getAttribute("aria-expanded") === "true";
        const navigations = document.querySelectorAll(`[role='navigation']`);
        const currentNavigation = document.querySelector(
            `[aria-labelledby=${target.id}]`
        );

        removeEvent(currentNavigation, "transitionend");
        addEventOnce(
            currentNavigation,
            "transitionend",
            () => this.handleAnimation(currentNavigation, expanded),
            false
        );

        navigations.forEach(nav => {
            if (expanded) {
                nav.classList.remove(this.dom.classList.navigationPanelOpen);
                navigationWrapper.classList.remove(
                    this.dom.classList.navigationWrapperOpen
                );
            } else {
                nav.classList.add(this.dom.classList.navigationPanelOpen);
                navigationWrapper.classList.add(
                    this.dom.classList.navigationWrapperOpen
                );
            }
        });

        if (!expanded) {
            const active = [...navigations].filter(nav =>
                nav.classList.contains(this.dom.classList.navigationPanelOpen)
            );
            active.forEach(nav => {
                nav.classList.remove(this.dom.classList.navigationPanelOpen);
            });

            this.resetMainNavigation();
            currentNavigation.classList.add(
                this.dom.classList.navigationPanelOpen
            );
        }

        target.setAttribute("aria-expanded", !expanded);

        onClickOutside(currentNavigation, ({ target }) => {
            const wrapper = !target.closest(".navigation");
            if (wrapper) {
                this.handleAnimation(currentNavigation, true);
                this.resetMainNavigation();
                this.resetSubNavigation();
                navigationWrapper.classList.remove(
                    this.dom.classList.navigationWrapperOpen
                );
                disableScrollLock();
            }
            removeOnClickOutside(currentNavigation, this);
        });

        removeEvent(window, "keydown");
        window.addEventListener("keydown", event => {
            if (event.key === "Escape") {
                this.handleAnimation(currentNavigation, true);
                this.resetMainNavigation();
                this.resetSubNavigation();
                navigationWrapper.classList.remove(
                    this.dom.classList.navigationWrapperOpen
                );
                disableScrollLock();
            }
        });

        expanded ? disableScrollLock() : enableScrollLock();
    }

    toggleMobileNavigation(event, elem, className) {
        if (hasClass(elem, className)) {
            elem.classList.remove(className);
            disableScrollLock();
            this.resetMainNavigation();
            this.resetSubNavigation();
        } else if (event.target.classList.length === 0) {
            return true;
        } else {
            elem.classList.add(className);
            enableScrollLock();
        }
    }

    handleAnimation(currentNavigation, expanded) {
        if (expanded) {
            currentNavigation.classList.remove(
                this.dom.classList.navigationPanelOpen
            );
        }
    }

    resetMainNavigation() {
        const buttons = document.querySelectorAll(
            `.${this.dom.classList.navigationButton}`
        );
        const navigations = document.querySelectorAll(`[role='navigation']`);
        navigations.forEach(nav => {
            nav.setAttribute("aria-expanded", false);
            nav.classList.remove(`.${this.dom.classList.navigationPanelOpen}`);
        });
        buttons.forEach(button => {
            button.setAttribute("aria-expanded", false);
        });
    }

    resetSubNavigation() {
        const subNavigationList = document.querySelectorAll(
            `.${this.dom.classList.subNavigationList}`
        );
        const subNavigationTrigger = document.querySelectorAll(
            `.${this.dom.classList.subNavigationTrigger}`
        );
        const navigationPanel = document.querySelectorAll(
            `.${this.dom.classList.navigationPanel}`
        );
        navigationPanel.forEach(item => {
            item.classList.remove(this.dom.classList.navigationPanelOpen);
        });
        subNavigationList.forEach(item => {
            item.removeAttribute("style");
            item.classList.remove(this.dom.classList.subNavigationOpen);
        });
        subNavigationTrigger.forEach(item => {
            item.classList.remove(this.dom.classList.subNavigationActive);
        });
    }

    attachScrollEvent() {
        const navigationList = this.dom.container.querySelector(
            `.${this.dom.classList.navigationList}`
        );
        const navigationClose = this.dom.container.querySelector(
            `.${this.dom.classList.mobileNavigationTrigger}-close`
        );
        const navigationCloseActive = `${this.dom.classList.mobileNavigationTrigger}-close--active`;

        navigationList.addEventListener("scroll", () => {
            if (navigationList.scrollTop >= 100) {
                navigationClose.classList.add(navigationCloseActive);
            } else {
                navigationClose.classList.remove(navigationCloseActive);
            }
        });
    }

    handleSamePageNavitation() {
        const navigationList = this.dom.container.querySelector(
            `.${this.dom.classList.navigationList}`
        );
        const page = this.dom.pathname.split("/").slice(-1)[0];
        const links = navigationList.querySelectorAll("a");
        links.forEach(item => {
            const currentPage = item
                .getAttribute("href")
                .split("/")
                .slice(-1)[0];
            if (currentPage.match("#")) {
                const url = currentPage.split("#");
                if (url[0] === page) {
                    item.classList.add(
                        `${this.dom.classList.samePageNavigationLink}`
                    );
                }
            }
        });
    }

    handleExternalLinkEvent() {
        const externalLink = this.dom.container
            .querySelector(`.${this.dom.classList.navigationList}`)
            .querySelectorAll("a");
        externalLink.forEach(item => {
            if (this.siteSwitchLink(item)) {
                item.classList.add(
                    "button",
                    "button--tertiary",
                    "button--icon-external-link",
                    "navigation__external-link"
                );
            }
        });
    }

    init(domReference) {
        const self = this;
        const navigationList = domReference.querySelector(
            `.${self.dom.classList.navigationList}`
        );
        if (navigationList == null) {
            return;
        }
        self.handleSamePageNavitation();
        const mainNavigationTrigger = domReference.querySelectorAll(
            `.${self.dom.classList.navigationButton}`
        );
        const mobileNavigationTrigger = domReference.querySelectorAll(
            `.${self.dom.classList.mobileNavigationTrigger}`
        );
        const mainNavigationList = domReference.querySelector(
            `.${self.dom.classList.navigationListWrapper}`
        );
        const subNavigationWrapper = domReference.querySelectorAll(
            `.${self.dom.classList.subNavigationWrapper}`
        );
        const navigationBackTop = domReference.querySelectorAll(
            `.${self.dom.classList.navigationBackTop}`
        );
        const samePageNavigationLinks = navigationList.querySelectorAll(
            `.${self.dom.classList.samePageNavigationLink}`
        );

        function prepareNav(nav) {
            const navBar = nav.querySelector("ul");
            const navElems = nav.querySelectorAll("li[data-level] > ul");
            navBar.style.left = "0%";
            nav.onclick = e => {
                e.stopPropagation();
                startNav(e, navBar, navElems);
            };
        }

        function startNav(e, navBar, navElems) {
            const trigger = domReference.querySelectorAll(
                `.${self.dom.classList.subNavigationTrigger}`
            );
            if (e.target.dataset && e.target.dataset.level) {
                if (currentBreakpoint < breakpointIndex.lg) {
                    navBar.style.left = -(e.target.dataset.level * 100) + "%";
                } else {
                    navBar.style.left = "0%";
                }
                [].forEach.call(navElems, function(item) {
                    item.classList.remove(self.dom.classList.subNavigationOpen);
                });
                trigger.forEach(item => {
                    const siblings = getSiblings(e.target, true);
                    const dataLevel = parseInt(item.getAttribute("data-level"));
                    const subNavigationItems = item
                        .closest("ul")
                        .querySelectorAll(`[data-level="${dataLevel + 1}"]`);
                    siblings.forEach(subItem =>
                        subItem.classList.remove(
                            self.dom.classList.subNavigationActive
                        )
                    );
                    subNavigationItems.forEach(subItem =>
                        subItem.classList.remove(
                            self.dom.classList.subNavigationActive
                        )
                    );
                });
                e.target.classList.add(self.dom.classList.subNavigationActive);
                e.target
                    .closest("ul")
                    .classList.add(self.dom.classList.subNavigationOpen);
                const subNavList = e.target.querySelector("ul");
                subNavList.classList.add(self.dom.classList.subNavigationOpen);
            }
            if (e.target.className === self.dom.classList.subNavigationBack) {
                const leftPos = parseInt(navBar.style.left);
                navBar.style.left = leftPos + 100 + "%";
            }
        }

        function resizeEvent() {
            if (currentBreakpoint >= breakpointIndex.lg) {
                document
                    .querySelector(
                        `.${self.dom.classList.navigationListWrapper}`
                    )
                    .classList.remove(
                        self.dom.classList.navigationListWrapperOpen
                    );
            }
        }

        addEvent(mainNavigationTrigger, "click", event =>
            self.togglePanel(event)
        );

        addEvent(mobileNavigationTrigger, "click", event =>
            self.toggleMobileNavigation(
                event,
                mainNavigationList,
                self.dom.classList.navigationListWrapperOpen
            )
        );

        addEvent(navigationBackTop, "click", () => {
            self.resetSubNavigation();
            self.resetMainNavigation();
        });

        onWindowResize(resizeEvent);

        if (subNavigationWrapper) {
            Array.from(subNavigationWrapper).forEach(item => {
                prepareNav(item);
            });
        }

        if (navigationList !== null) {
            self.attachScrollEvent();
            self.handleExternalLinkEvent();
        }

        addEvent(samePageNavigationLinks, "click", event => {
            event.preventDefault();
            const navigationWrapper = document.querySelector(
                `.${self.dom.classList.navigationWrapper}`
            );
            const navigationListWrapper = document.querySelector(
                `.${self.dom.classList.navigationListWrapper}`
            );
            const samePageLink = event.target.href.split("#")[1];
            const section = document.querySelector(`[id=${samePageLink}]`);
            self.resetSubNavigation();
            self.resetMainNavigation();
            navigationWrapper.classList.remove(
                self.dom.classList.navigationWrapperOpen
            );
            navigationListWrapper.classList.remove(
                self.dom.classList.navigationListWrapperOpen
            );
            disableScrollLock();
            scrollTo(section, 600, window, [0, -self.dom.navHeight + 1]).then(
                () => {
                    const newLoc = (self.dom.hash = `#${samePageLink}`);
                    window.history.replaceState(null, null, newLoc);
                }
            );
        });
    }
}
