import { onReady } from "../../utils/events/onReady";
import {
    currentWindowWidth,
    onWindowResize
} from "../../utils/events/onWindowResize";
import { debounce } from "../../utils/dom/debounce";

export class Carousel {
    /**
     * Internal placeholder for DOM-objects
     *
     * @type {object}
     * @ignore
     */
    dom = {
        container: undefined
    };

    /**
     *
     * @param {Element} domReference - The element to work from
     */
    constructor(domReference) {
        this.dom.container = domReference;
        this.dom.currentItem = 0;
        this.dom.lastWidth = window.innerWidth;
        this.dom.carouselItemWrapper = domReference.querySelector(
            ".carousel__item-wrapper"
        );
        this.dom.carouselLeftButton = domReference.querySelector(
            ".carousel__arrow--left"
        );
        this.dom.carouselRightButton = domReference.querySelector(
            ".carousel__arrow--right"
        );
        this.dom.carouselItem = domReference.querySelector(".carousel__item");
        this.dom.carouselCircleWrapper = domReference.querySelector(
            ".carousel__circle-wrapper"
        );
        onReady(() => this.init(domReference));
    }

    setColumns() {
        const carousel = this.dom.container;
        if (carousel.dataset.carouselEnable === "true") {
            if (carousel.dataset.carouselColumns === "4") {
                this.dom.carouselItemWrapper
                    .querySelectorAll(".carousel__item")
                    .forEach(item =>
                        item.classList.add(
                            `col--md-6`,
                            `col--lg-${12 / carousel.dataset.carouselColumns}`
                        )
                    );
            } else {
                this.dom.carouselItemWrapper
                    .querySelectorAll(".carousel__item")
                    .forEach(item =>
                        item.classList.add(
                            `col--md-${12 / carousel.dataset.carouselColumns}`
                        )
                    );
            }
            if (
                carousel.dataset.carouselColumns >=
                this.dom.carouselItemWrapper.childElementCount
            ) {
                this.dom.carouselRightButton.classList.add(
                    "carousel__arrow--right--hidden"
                );
            }
        } else {
            this.dom.carouselItemWrapper.classList.add(
                `carousel__item-wrapper--col-${carousel.dataset.carouselColumns}`
            );
        }
    }
    setActiveAttributes() {
        const carouselCircles = this.dom.container.querySelectorAll(
            ".carousel__circle"
        );
        carouselCircles.forEach(item => {
            item.classList.remove("carousel__circle--active");
        });
        this.dom.carouselItemWrapper
            .querySelectorAll(".carousel__item")
            .forEach(item => (item.ariaHidden = true));
        this.dom.carouselItemWrapper
            .querySelectorAll("a")
            .forEach(link => link.setAttribute("tabindex", "-1"));
        let items =
            this.dom.carouselItemWrapper.childElementCount <
            this.dom.container.dataset.carouselColumns
                ? this.dom.carouselItemWrapper.childElementCount
                : this.dom.container.dataset.carouselColumns;
        if (window.innerWidth > 992) {
            for (let i = 0; i < items; i++) {
                carouselCircles[this.dom.currentItem + i].classList.add(
                    "carousel__circle--active"
                );
                this.dom.carouselItemWrapper.children[
                    this.dom.currentItem + i
                ].ariaHidden = false;
                this.dom.carouselItemWrapper.children[this.dom.currentItem + i]
                    .querySelector("a")
                    ?.removeAttribute("tabindex");
            }
        }
    }
    resetCarousel() {
        if (this.dom.lastWidth !== currentWindowWidth) {
            if (currentWindowWidth >= 992) {
                this.dom.carouselItemWrapper.style.transform = ``;
                this.dom.carouselRightButton.classList.remove(
                    "carousel__arrow--right--hidden"
                );
                this.dom.carouselLeftButton.classList.add(
                    "carousel__arrow--left--hidden"
                );
                this.dom.currentItem = 0;
                this.setActiveAttributes();
            } else {
                this.dom.carouselItemWrapper.style.transform = ``;
                this.setSelected();
            }
            this.dom.lastWidth = currentWindowWidth;
        }
    }
    previousItem() {
        //show right button
        this.dom.carouselRightButton.classList.remove(
            "carousel__arrow--right--hidden"
        );
        const slideWidth = this.dom.carouselItem.clientWidth;
        //if reached first item, hide left button

        if (this.dom.currentItem === 1) {
            this.dom.carouselLeftButton.classList.add(
                "carousel__arrow--left--hidden"
            );
        }
        if (this.dom.currentItem !== 0) {
            this.dom.currentItem -= 1;
        }
        this.dom.carouselItemWrapper.style.transform = `translateX(-${(slideWidth +
            32) *
            this.dom.currentItem}px)`;
        this.setActiveAttributes();
    }

    nextItem() {
        //show the left button + remove margin-left
        this.dom.carouselLeftButton.classList.remove(
            "carousel__arrow--left--hidden"
        );
        //slide the carousel
        const slideWidth = this.dom.carouselItem.clientWidth;
        if (
            this.dom.currentItem !==
            this.dom.carouselItemWrapper.childElementCount - 1
        ) {
            this.dom.currentItem += 1;
        }
        this.dom.carouselItemWrapper.style.transform = `translateX(-${(slideWidth +
            32) *
            this.dom.currentItem}px)`;
        //if reached last item, hide the right button
        if (
            this.dom.currentItem +
                Number(this.dom.container.dataset.carouselColumns) ===
            this.dom.carouselItemWrapper.childElementCount
        ) {
            this.dom.carouselRightButton.classList.add(
                "carousel__arrow--right--hidden"
            );
        }
        this.setActiveAttributes();
    }

    generateCarouselCircle() {
        for (
            let i = 0;
            i < this.dom.carouselItemWrapper.childElementCount;
            i++
        ) {
            const circle = document.createElement("div");
            circle.classList.add("carousel__circle");
            this.dom.carouselCircleWrapper?.appendChild(circle);
        }
        this.dom.carouselCircleWrapper?.classList.add(
            "carousel__circle-wrapper--show"
        );
    }

    // using overflow-x:scroll, scroll-snap css properties and setSelected function to achieve smooth scroll on mobile devices
    setSelected() {
        const circles = this.dom.carouselCircleWrapper.querySelectorAll(
            ".carousel__circle"
        );
        circles.forEach(circle => {
            circle.classList.remove("carousel__circle--active");
        });
        this.dom.carouselItemWrapper
            .querySelectorAll(".carousel__item")
            .forEach(item => {
                item.classList.remove("selected");
                item.ariaHidden = true;
                item.querySelector("a")?.setAttribute("tabindex", "-1");
            });
        const scrolllength = this.dom.carouselItemWrapper.querySelectorAll(
            ".carousel__item"
        )[1]?.offsetLeft;
        const nthchild = Math.round(
            this.dom.carouselItemWrapper.scrollLeft / scrolllength + 1
        );
        this.dom.carouselCircleWrapper
            .querySelector(`div:nth-child(${nthchild})`)
            .classList.add("carousel__circle--active");
        this.dom.carouselItemWrapper
            .querySelector(`li:nth-child(${nthchild})`)
            .classList.add("selected");

        this.dom.carouselItemWrapper.children[nthchild - 1].ariaHidden = false;
        this.dom.carouselItemWrapper.children[nthchild - 1]
            .querySelector("a")
            ?.removeAttribute("tabindex");
    }
    init(domReference) {
        const carousel = domReference;
        this.setColumns();

        if (carousel.dataset.carouselEnable === "true") {
            carousel.classList.add("carousel--enabled");
            this.dom.carouselLeftButton.addEventListener("click", () =>
                this.previousItem()
            );
            this.dom.carouselRightButton.addEventListener("click", () =>
                this.nextItem()
            );
            this.generateCarouselCircle();
            this.setActiveAttributes();
            this.setSelected();
            onWindowResize(() => this.resetCarousel());
            this.dom.carouselItemWrapper.addEventListener(
                "scroll",
                debounce(() => this.setSelected())
            );
        }
    }
}
