import { createElement } from "./createElement";

export const getScrollBarWidth = () => {
    return document.documentElement.style.setProperty(
        "--scrollbar-width",
        window.innerWidth - document.documentElement.clientWidth + "px"
    );
};

export const getNavHeight = () => {
    return document.documentElement.style.setProperty(
        "--nav-height",
        document.querySelector("header")?.clientHeight + "px"
    );
};

export const getInPageNavigationScrollHeight = () => {
    const inPageNavigation = document.querySelector(".in-page-navigation");
    if (inPageNavigation !== null) {
        const inPageNavigationInner = inPageNavigation.querySelector(
            ".in-page-navigation__inner"
        );
        const inPageNavigationInnerClone = inPageNavigationInner.cloneNode(
            true
        );
        inPageNavigationInnerClone.classList.add("in-page-navigation--temp");
        const div = createElement("div");
        div.appendChild(inPageNavigationInnerClone);
        div.classList.add(
            "in-page-navigation",
            "in-page-navigation__cloned",
            "scroll--active",
            "in-page-navigation--active"
        );
        inPageNavigation.parentElement.appendChild(div);
        document.documentElement.style.setProperty(
            "--in-page-navigation-scroll-height",
            document.querySelector(".in-page-navigation--temp")?.clientHeight +
                "px"
        );
        div.remove();
    }
};

export const getInPageNavigationHeight = () => {
    document.documentElement.style.setProperty(
        "--in-page-navigation-height",
        document.querySelector(".in-page-navigation__inner")?.clientHeight +
            "px"
    );
};

export const getInPageNavigationOffset = () => {
    const inPageNavigation = document.querySelector(".in-page-navigation");
    if (inPageNavigation !== null) {
        const inPageNavigationInner = inPageNavigation.querySelector(
            ".in-page-navigation__inner"
        );
        if (document.querySelector(".hero__tertiary") !== null) {
            const wrapper = document.querySelector("main > .component-margin");
            const wrapperStyle = getComputedStyle(wrapper);
            const wrapperMargin = parseInt(wrapperStyle.marginBottom);
            document.documentElement.style.setProperty(
                "--in-page-navigation-offset",
                inPageNavigationInner?.clientHeight + wrapperMargin + "px"
            );
        } else if (document.querySelector(".hero__product") !== null) {
            const inPageNavigationStyle = getComputedStyle(inPageNavigation);
            const inPageNavigationPadding = parseInt(
                inPageNavigationStyle.paddingBottom
            );
            document.documentElement.style.setProperty(
                "--in-page-navigation-offset",
                inPageNavigationInner?.clientHeight +
                    inPageNavigationPadding +
                    "px"
            );
        } else {
            document.documentElement.style.setProperty(
                "--in-page-navigation-offset",
                inPageNavigationInner?.clientHeight + "px"
            );
        }
    }
};
