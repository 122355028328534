/* global eisai */

import { onReady } from "../../utils/events/onReady";

export class ThreeQ {
    /**
     * Internal placeholder for DOM-objects
     *
     * @type {object}
     * @ignore
     */
    dom = {
        container: undefined
    };

    /**
     *
     * @param {Element} domReference - The element to work from
     */
    constructor(domReference) {
        this.dom.container = domReference;

        onReady(() => this.init(domReference));
    }

    onThreeQLoadEvent() {
        const baseURL = eisai.threeQBaseURL;
        const path = eisai.threeQPath;
        const apiKey = eisai.threeQAPIKey;
        const region = this.dom.container.parentElement.className;
        fetch(`${baseURL}${path}/projects`, {
            method: "GET",
            headers: {
                "X-AUTH-APIKEY": apiKey
            }
        })
            .then(res => {
                if (res.status === 200) {
                    return res.json();
                }
            })
            .then(data => {
                if (region !== "undefined") {
                    data = data.Projects.filter(
                        item => item.Label === `EISAI (${region})`
                    );
                    this.dom.container.innerHTML = `<li>${JSON.stringify(
                        data,
                        null,
                        4
                    )}</li>`;
                } else {
                    this.dom.container.innerHTML = `<li>${JSON.stringify(
                        data,
                        null,
                        4
                    )}</li>`;
                }
            });
    }

    init() {
        this.onThreeQLoadEvent();
    }
}
