export const debounce = func => {
    // Setup a timer
    let timeout;
    // Return a function to run debounced
    return function() {
        // Setup the arguments
        let context = this;
        let args = arguments;
        // If there's a timer, cancel it
        if (timeout) {
            window.cancelAnimationFrame(timeout);
        }
        // Setup the new requestAnimationFrame()
        timeout = window.requestAnimationFrame(function() {
            func.apply(context, args);
        });
    };
};
