import { onReady } from "../../utils/events/onReady";
import { addEvent } from "../../utils/events/events";

export class References {
    /**
     * Internal placeholder for DOM-objects
     *
     * @type {object}
     * @ignore
     */
    dom = {
        container: undefined
    };

    /**
     *
     * @param {Element} domReference - The element to work from
     */
    constructor(domReference) {
        this.dom.container = domReference;
        this.dom.classList = {
            openWrapperClass: "references--open",
            openListClass: "references__list--open"
        };

        onReady(() => this.init(domReference));
    }

    onReferencesClickEvent(event) {
        event.preventDefault();
        const elem = event.currentTarget;
        const parent = elem.closest("ul");
        if (elem.classList.contains(this.dom.classList.openListClass)) {
            elem.classList.remove(this.dom.classList.openListClass);
            parent.classList.remove(this.dom.classList.openWrapperClass);
            elem.setAttribute("aria-expanded", false);
        } else {
            elem.classList.add(this.dom.classList.openListClass);
            parent.classList.add(this.dom.classList.openWrapperClass);
            elem.setAttribute("aria-expanded", true);
        }
    }

    /**
     * Bind events we need to the DOM-elements.
     */
    bindEvents() {
        addEvent(
            this.dom.referencesTrigger,
            "click",
            this.onReferencesClickEvent.bind(this)
        );
    }

    init(domReference) {
        this.dom.referencesTrigger = domReference.querySelectorAll(
            ".references__list .grid > a"
        );
        this.bindEvents();
    }
}
