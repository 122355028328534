import { onReady } from "../../utils/events/onReady";
import { addEvent } from "../../utils/events/events";
import { scrollTo } from "../../utils/dom/scrollTo";

export class ScrollTop {
    /**
     * Internal placeholder for DOM-objects
     *
     * @type {object}
     * @ignore
     */
    dom = {
        container: undefined
    };

    /**
     *
     * @param {Element} domReference - The element to work from
     */
    constructor(domReference) {
        this.dom.container = domReference;
        onReady(() => this.init(domReference));
    }

    onScrollTopClickEvent(event) {
        event.preventDefault();
        scrollTo(0, 600);
    }

    /**
     * Bind events we need to the DOM-elements.
     */
    bindEvents() {
        addEvent(
            this.dom.container,
            "click",
            this.onScrollTopClickEvent.bind(this)
        );
    }

    init() {
        this.bindEvents();
    }
}
